import { formatDate } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, LOCALE_ID, inject } from '@angular/core';
import { Observable, map } from 'rxjs';
import { environment } from '../../environments/environment';
import { createLawArticleTitle } from './helpers/law-article.helper';
import { LawArticle, LawArticleVersion } from './law-article';
import {
  LawArticleParams,
  LawArticleSearchResult,
  LawArticleSearchResults,
} from './law-article.service';
import {
  LegacyLawArticle,
  LegacyLawArticleVersion,
} from './legacy-law-article';

export type LegacyLawArticleSort = 'SCORE' | 'DATE';

export type LegacyLawArticleSearchResult = Omit<
  LegacyLawArticle,
  'content' | 'versions'
>;

export interface LegacyLawArticleSearchResultLegalCode {
  code: string;
  count: number;
}

export interface LegacyLawArticleSearchResults {
  suggestions: string;
  search_suggestion: string;
  resultats: LegacyLawArticleSearchResult[];
  codes: LegacyLawArticleSearchResultLegalCode[];
  duration: number;
  numres: number;
  translated_search: string;
}

/**
 * Arbitrary values from API PHP that allows
 * to determine the article code effective status.
 * So yeah, the end of the world is scheduled for 2999.
 */
export const ARTICLE_CODE_INFINITY_DATE = '2999-01-01';

@Injectable({
  providedIn: 'root',
})
export class LegacyLawArticleService {
  private readonly http = inject(HttpClient);
  private readonly locale = inject(LOCALE_ID);

  all(params?: LawArticleParams): Observable<LawArticleSearchResults> {
    let httpParams = new HttpParams({
      fromObject: {
        jurisprudence: 'article_code_doc',
      },
    });

    if (params?.pageIndex !== undefined && params?.pageSize !== undefined) {
      httpParams = httpParams.set('offset', params.pageIndex * params.pageSize);
    }
    if (params?.pageSize !== undefined) {
      httpParams = httpParams.set('nb_res', params.pageSize);
    }
    if (params?.filters?.query) {
      httpParams = httpParams.set('free_text', params.filters.query);
    }
    if (params?.sort) {
      httpParams = httpParams.set('sort', params.sort);
    }
    if (params?.filters?.date?.start) {
      httpParams = httpParams.set(
        'date_debut',
        this.formatDate(params.filters.date.start),
      );
    }
    if (params?.filters?.date?.end) {
      httpParams = httpParams.set(
        'date_fin',
        this.formatDate(params.filters.date.end),
      );
    }
    if (params?.filters?.legalCode) {
      httpParams = httpParams.set('filter_by_code', params.filters.legalCode);
    }

    return this.http
      .get<LegacyLawArticleSearchResults>(`${environment.apiUrl}/search`, {
        params: httpParams,
      })
      .pipe(
        map((data) => ({
          data: data.resultats.map((item) =>
            this.formatLawArticleSearchResult(item),
          ),
          duration: data.duration,
          total: data.numres,
        })),
      );
  }

  find(id: string): Observable<LawArticle> {
    return this.http
      .get<LegacyLawArticle>(`${environment.apiUrl}/code/article/${id}`)
      .pipe(map((lawArticle) => this.formatLawArticle(lawArticle)));
  }

  private formatLawArticleSearchResult(
    legacySearchResult: LegacyLawArticleSearchResult,
  ): LawArticleSearchResult {
    const lawArticleNumber = this.formatLawArticleNumber(
      legacySearchResult.number,
    );

    return {
      id: legacySearchResult.id,
      title: this.formatLawArticleTitle(legacySearchResult),
      status: legacySearchResult.status,
      date: {
        start: legacySearchResult.start_date,
        end:
          legacySearchResult.end_date !== ARTICLE_CODE_INFINITY_DATE
            ? legacySearchResult.end_date
            : null,
      },
      articleNumber: lawArticleNumber,
      legalCode: {
        name: legacySearchResult.code.name,
      },
      highlight: legacySearchResult.extrait,
      summary: legacySearchResult.resume,
    };
  }

  private formatLawArticle(legacyLawArticle: LegacyLawArticle): LawArticle {
    const lawArticleNumber = this.formatLawArticleNumber(
      legacyLawArticle.number,
    );

    return {
      id: legacyLawArticle.id,
      title: this.formatLawArticleTitle(legacyLawArticle),
      status: legacyLawArticle.status,
      date: {
        start: legacyLawArticle.start_date,
        end:
          legacyLawArticle.end_date !== ARTICLE_CODE_INFINITY_DATE
            ? legacyLawArticle.end_date
            : null,
      },
      articleNumber: lawArticleNumber,
      legalCode: {
        name: legacyLawArticle.code.name,
      },
      content: legacyLawArticle.content,
      versions: legacyLawArticle.versions.map((version) =>
        this.formatLawArticleVersion(version),
      ),
      summary: legacyLawArticle.resume,
      highlight: legacyLawArticle.extrait,
    };
  }

  private formatLawArticleTitle(
    legacyLawArticle: LegacyLawArticle | LegacyLawArticleSearchResult,
  ): string {
    return createLawArticleTitle(
      legacyLawArticle.number,
      legacyLawArticle.code.name,
    );
  }

  private formatLawArticleVersion(
    version: LegacyLawArticleVersion,
  ): LawArticleVersion {
    return {
      id: version.id,
      status: version.status,
      date: {
        start: version.start_date,
        end:
          version.end_date !== ARTICLE_CODE_INFINITY_DATE
            ? version.end_date
            : null,
      },
    };
  }

  private formatLawArticleNumber(lawArticleNumber: string): string {
    return lawArticleNumber.replace(/^([LRDA])(\d+)/, '$1.$2');
  }

  private formatDate(date: Date | string): string {
    return formatDate(date, 'yyyy-MM-dd', this.locale);
  }
}
