export function createLawArticleTitle(
  articleNumber: string,
  legalCodeName: string,
): string {
  const legalCodeNameLowerCased = legalCodeName.toLocaleLowerCase();
  let preposition = 'du ';

  if (
    legalCodeNameLowerCased.includes('arrêté') ||
    legalCodeNameLowerCased.includes('ordonnance')
  ) {
    preposition = "de l'";
  } else if (
    legalCodeNameLowerCased.includes('loi') ||
    legalCodeNameLowerCased.includes('déclaration')
  ) {
    preposition = 'de la ';
  }
  return `Article ${articleNumber} ${preposition}${legalCodeName}`;
}
